import React, { useContext, useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import ReactSelect from '../../ReactSelect/ReactSelect';
import { AllUsersOption } from '../../ReactSelect/ReactSelectOption';
import { ReactComponent as Description } from '../../../images/icons/description.svg';
import { AuthContext } from '../../../context/AuthContext';
import { customModalStyles } from '../../../config/config';
import { useCrypto } from '../../../hooks/crypto.hook';
import { useHttp } from '../../../hooks/http.hook';
import './GenerateReportModal.css';

export const GenerateReportModal = ({
  isOpen,
  onRequestClose,
  tenant,
  department,
  location,
  loading,
  generateReportCallback,
  generateReportButtonDisabled,
}) => {
  const { decryptData } = useCrypto();
  const { request,} = useHttp();
  const { token, allUsers } = useContext(AuthContext);

  const [admins, setAdmins] = useState([]);
  const [selectedReceivers, setSelectedReceivers] = useState([]);
  const [usersOptionList, setUsersOptionList] = useState([]);
  const [adminsOptionList, setAdminsOptionList] = useState([]);

  useEffect(() => {
    const optionList = allUsers
      .filter(user =>
        tenant ? user.tenant.id === tenant.value : user &&
        department ? user.department === department.value : user &&
        location ? user.location === location.value : user)
      .map(user => ({
        ...user,
        value: user.id,
        label: user.email,
        caption: user.firstName + ' ' + user.secondName,
      }));
    setUsersOptionList(
      optionList
      .filter(user => !selectedReceivers.some(receiverSelected => receiverSelected.email === user.email))
    );

  }, [allUsers, selectedReceivers, tenant, department, location]);

  useEffect(() => {
    const optionList = admins
      .filter(admin => {
        if (admin.role === 'owner') return admin
        else if (
          (admin.role === 'admin' || admin.role === 'tenant-admin') &&
          tenant && admin?.tenants?.tenantsIds?.includes(tenant.value) 
        ) return admin
        return null
      })
      .filter(Boolean)
      .map(user => ({
        ...user,
        value: user.id,
        label: user.email,
        caption: user.firstName + ' ' + user.lastName,
      }));
    setAdminsOptionList(
      optionList
      .filter(user => !selectedReceivers.some(receiverSelected => receiverSelected.email === user.email))
    );

  }, [allUsers, selectedReceivers, tenant, department, location, admins]);

  const fetchAccountAdmins = useCallback(async () => {
    try {
      if (token) {
        const users = await request('/back_office/api/user/account_users', 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptUsers = decryptData(users);
        setAdmins(decryptUsers);

        return decryptUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, request]);

  useEffect(() => {
    fetchAccountAdmins()
  }, [fetchAccountAdmins]);

  const handleUserSelect = (data) => {
    setSelectedReceivers(prev => [...prev, data]);
  };

  const handleAdminSelect = (data) => {
    setSelectedReceivers(prev => [...prev, data]);
  };

  const removeSelectedReceivers = (index) => {
    setSelectedReceivers(prev => prev.filter((_, i) => i !== index));
  };

  const closeModal = () => {
    onRequestClose();
    setSelectedReceivers([]);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel='Frequency Modal'
      style={customModalStyles}
    >
      <div className='frequency-modal'>
        <div className='frequency-content'>
          <span className='card-title'>Select analitycs report receivers</span>
          <div className='select-container'>
            <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--user')}>
              <ReactSelect
                options={adminsOptionList}
                optionComponent={AllUsersOption}
                classNames={cn({'user-select--disabled': loading})}
                placeholder={'Select Admin...'}
                value={null}
                onChange={handleAdminSelect}
                isSearchable={true}
                isClearable={true}
                isDisabled={loading}
                optionHeight={50}
                rows={6}
                isLoading={loading}
              />
            </div>

            <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--user')}>
              <ReactSelect
                options={usersOptionList}
                optionComponent={AllUsersOption}
                classNames={cn({'user-select--disabled': loading})}
                placeholder={'Select User...'}
                value={null}
                onChange={handleUserSelect}
                isSearchable={true}
                isClearable={true}
                isDisabled={loading}
                optionHeight={50}
                rows={6}
                isLoading={loading}
              />
            </div>
          </div>

          <div className='report-receivers'>
            {!!selectedReceivers.length && <span className='card-title'>Report receivers:</span>}
            {selectedReceivers?.map((user, index) => (
              <div key={index} className='analytics-report-item'>
                <div>
                  <span className='analytics-report-item-name'>{user?.firstName} {user?.secondName || user?.lastName}</span>
                  <span className='analytics-report-item-email'>{user?.email}</span>
                </div>
                {!generateReportButtonDisabled && 
                  <i
                    role='button'
                    onClick={() => removeSelectedReceivers(index)}
                    className={cn({ 'tenants-clear-disabled': false})}
                  />
                }
              </div>
            ))}
          </div>

          <div className='analytics-report-button modal-button'>
            {!generateReportButtonDisabled ? (
              <button
                className='generate-report-button'
                onClick={async () => {
                  const res = await generateReportCallback(selectedReceivers);
                  if (res) closeModal();
                }}
                disabled={generateReportButtonDisabled || !selectedReceivers.length}
              >
              <Description />
                Generate Report
              </button>
            ) : (
              <button
                className="csv-download-loading"
                type="button"
                disabled={generateReportButtonDisabled || !selectedReceivers.length}
              >
                <span className="mini-loader-csv"></span>
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};
