import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import cn from 'classnames';
import { AnalyticsGeneralContext } from '../../../context/AnalyticsGeneralContext';
import { AuthContext } from '../../../context/AuthContext';
import { useHttp } from '../../../hooks/http.hook';
import { useCrypto } from '../../../hooks/crypto.hook';
import { dateRangeOptionsAnalytics, lastThirtyDaysChunk } from '../../../config/config';
import { UserAnalyticsFilterBar } from '../UserAnalyticsFilterBar/UserAnalyticsFilterBar';
import { PhishingMetrics } from './PhishingMetrics';
import { TrendGraph } from '../TrendGraph/TrendGraph';
import { ProneTable } from '../ProneTable/ProneTable';
import { TrendGraphBar } from '../TrendGraph/TrendGraphBar';
import './Phishing.css';

export const Phishing = () => {
  const {
    tenants: contextTenants,
    departments: contextDepartments,
    locations: contextLocations
  } = useContext(AnalyticsGeneralContext);

  const { token, showToastMessage } = useContext(AuthContext);
  const { request, loading, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { encryptData, decryptData } = useCrypto();

  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [minMailingDate, setMinMailingDate] = useState(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
  const [maxMailingDate, setMaxMailingDate] = useState(moment().format('YYYY-MM-DD'));
  const [filteredMinMailingDate, setFilteredMinMailingDate] = useState(null);
  const [phishingData, setphishingData] = useState({});
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [dateRangeOptionsList, setDateRangeOptionsList] = useState(dateRangeOptionsAnalytics);
  const [selectedDateRange, setSelectedDateRange] = useState(dateRangeOptionsAnalytics[0]);
  const [tableData, setTableData] = useState([{
    title: 'Phish Click Table',
    columns: ['User', 'Phish Click Count'],
    data: [],
  }]);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    setTenants(contextTenants);
    setDepartments(contextDepartments);
    setLocations(contextLocations);
  }, [contextDepartments, contextLocations, contextTenants]);

  const fetchphishingData = useCallback(async () => {
    try {
      if (token && minMailingDate) {
        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate
        });
        const responsePhishingData = await request('/back_office/api/analytics/phishing', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptPhishingData = decryptData(responsePhishingData);
        setphishingData(decryptPhishingData);

        setFilteredMinMailingDate(decryptPhishingData.filteredMinMailingDate);
        setTableData(decryptPhishingData?.riskClickTableData || []);

        let filteredTenants = contextTenants;
        if (selectedDepartment || selectedLocation) {
          filteredTenants = filteredTenants?.filter(tenant =>
            decryptPhishingData?.tenantIds?.includes(tenant.id)
          );
        }

        let filteredDepartments = contextDepartments;
        if (selectedTenant || selectedLocation) {
          filteredDepartments = filteredDepartments?.filter(department =>
            decryptPhishingData?.departments?.includes(department.label)
          );
        }

        let filteredLocations = contextLocations;
        if (selectedTenant || selectedDepartment) {
          filteredLocations = filteredLocations?.filter(location =>
            decryptPhishingData?.locations?.includes(location.label)
          );
        }

        contextTenants?.length && setTenants(filteredTenants);
        contextDepartments?.length && setDepartments(filteredDepartments);
        contextLocations?.length && setLocations(filteredLocations);

        return decryptPhishingData;
      }
    } catch (error) {
      console.error('[Risk] ERROR:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedTenant, selectedDepartment, selectedLocation, minMailingDate, maxMailingDate]);

  const fetchPhishingGraphData = useCallback(async () => {
    try {
      if (token) {
        const data = encryptData({
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate
        });
        const responseData = await requestWithSecondaryLoading('/back_office/api/analytics/phishing_graphs', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptGraphData = decryptData(responseData);
        setGraphData(decryptGraphData);

        return decryptGraphData;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedTenant, minMailingDate, maxMailingDate]);

  useEffect(() => {
    fetchphishingData();
  }, [fetchphishingData]);

  useEffect(() => {
    fetchPhishingGraphData();
  }, [fetchPhishingGraphData]);

  const graphContainerRef = useRef(null);
  const trendGraphContainerRef = useRef(null);
  const tableContainerRef = useRef(null);

  const adjustTableHeight = useCallback(() => {
    if (
      tableContainerRef.current && trendGraphContainerRef.current
    ) {
      const trendGraphHeight = trendGraphContainerRef.current.clientHeight;
      tableContainerRef.current.style.maxHeight = `${trendGraphHeight}px`;
    }
  }, []);

  useEffect(() => {
    adjustTableHeight();
    window.addEventListener('resize', adjustTableHeight);
  
    return () => {
      window.removeEventListener('resize', adjustTableHeight);
    };
  }, [adjustTableHeight]);

  const exportPhishingUsersgCsv = async () => {
    try {
      if (token) {
        setDownloadLoading(true);

        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate
        });
        const response = await fetch('/back_office/api/analytics/phishing_users_csv', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data })
        });

        if (!response.ok) {
          setDownloadLoading(false);
          // eslint-disable-next-line no-throw-literal
          throw {error: 'Failed to export CSV file!'};
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        a.download = `phishing_users_${minMailingDate}_${maxMailingDate}.csv`;

        a.textContent = 'Download CSV';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setDownloadLoading(false);
        showToastMessage(null, 'CSV file with Risk Rating downloaded successfully');
      }
    } catch (error) {
      setDownloadLoading(false);
      showToastMessage(error?.error, null, error?.warning);
    }
  };

  return (
    <>
      <UserAnalyticsFilterBar
        loading={loading}
        tenants={tenants}
        departments={departments}
        locations={locations}
        minMailingDate={minMailingDate}
        setMinMailingDate={setMinMailingDate}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        dateRangeOptionsList={dateRangeOptionsList}
        filteredMinMailingDate={filteredMinMailingDate}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        setDateRangeOptionsList={setDateRangeOptionsList}
        maxMailingDate={maxMailingDate}
        setMaxMailingDate={setMaxMailingDate}
        generateReportButtonVisible={false}
      />

      <PhishingMetrics
        phishingData={phishingData}
        loading={loading}
      />

      <div className='trend-graphs-container trend-graphs-container-phishing'>
        <div className="trend-graphs-container-item" ref={graphContainerRef}>
          <div className="trend-graphs-container-item-graph" ref={trendGraphContainerRef}>
            <TrendGraph
            title='Phish Click Rate Trend Graph'
            loading={loading}
            minMailingDate={minMailingDate}
            maxMailingDate={maxMailingDate}
            graphData={phishingData?.phishClickTrendGraphData || {}}
            currValue={phishingData?.phishClickRate || '0'}
            diffValue={phishingData?.diffPhishClickRate || 0}
            tooltipName={'Phish Click Rate'}
            percentage={true}
            plusIsPositive={false}
            stepSizeY={10}
          />
          </div>
        </div>

        <div className="trend-graphs-container-item-table" ref={tableContainerRef}>
          <ProneTable
            tablesData={tableData}
            loading={loading}
            callback={exportPhishingUsersgCsv}
            downloadLoading={downloadLoading}
            height={graphContainerRef?.current?.clientHeight ? graphContainerRef?.current?.clientHeight - 120 : 0}
          />
        </div>
      </div>
      <div className={cn('trend-graphs-container', {
        'trend-graphs-container--loading': loading
      })}>
        <TrendGraphBar
          highlited={true}
          title='Department Phish Click Rate Trend Graph'
          loading={secondaryLoading}
          minMailingDate={minMailingDate}
          maxMailingDate={maxMailingDate}
          graphData={graphData?.departmentPhishClickGraphData || {}}
          tooltipName={'Phish Click Rate'}
          percentage={true}
          percentageTicks={true}
          highlitedText={selectedDepartment?.label}
        />
        <TrendGraphBar
          title='Location Phish Click Rate Trend Graph'
          loading={secondaryLoading}
          minMailingDate={minMailingDate}
          maxMailingDate={maxMailingDate}
          graphData={graphData?.locationPhishClickGraphData || {}}
          tooltipName={'Phish Click Rate'}
          percentage={true}
          percentageTicks={true}
          highlitedText={selectedLocation?.label}
        />
      </div>
    </>
  );
}
