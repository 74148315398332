import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { AnalyticsGeneralContext } from '../../../context/AnalyticsGeneralContext';
import { AuthContext } from '../../../context/AuthContext';
import { useHttp } from '../../../hooks/http.hook';
import { useCrypto } from '../../../hooks/crypto.hook';
import { dateRangeOptionsAnalytics, lastThirtyDaysChunk } from '../../../config/config';
import { UserAnalyticsFilterBar } from '../UserAnalyticsFilterBar/UserAnalyticsFilterBar';
import { RiskMetrics } from './RiskMetrics';
import { TrendGraph } from '../TrendGraph/TrendGraph';
import { ProneTable } from '../ProneTable/ProneTable';
import { ChartPieRiskLevels } from './ChartPieRiskLevels';
import './Risk.css';

export const Risk = () => {
  const {
    tenants: contextTenants,
    departments: contextDepartments,
    locations: contextLocations
  } = useContext(AnalyticsGeneralContext);

  const { token, showToastMessage } = useContext(AuthContext);
  const { request, loading, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { encryptData, decryptData } = useCrypto();

  const [tenants, setTenants] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locations, setLocations] = useState([]);
  const [minMailingDate, setMinMailingDate] = useState(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
  const [maxMailingDate, setMaxMailingDate] = useState(moment().format('YYYY-MM-DD'));
  const [filteredMinMailingDate, setFilteredMinMailingDate] = useState(null);
  const [riskData, setRiskData] = useState({});
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [dateRangeOptionsList, setDateRangeOptionsList] = useState(dateRangeOptionsAnalytics);
  const [selectedDateRange, setSelectedDateRange] = useState(dateRangeOptionsAnalytics[0]);
  const [tableData, setTableData] = useState([{
    title: 'Risk Rating Table',
    columns: ['User', 'Risk Status'],
    data: [],
    riskStatus: true,
  }]);
  const [dataReady, setDataReady] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  useEffect(() => {
    setTenants(contextTenants);
    setDepartments(contextDepartments);
    setLocations(contextLocations);
  }, [contextDepartments, contextLocations, contextTenants]);

  useEffect(() => {
    if (secondaryLoading) setDataReady(false);
  }, [secondaryLoading]);

  const fetchRiskData = useCallback(async () => {
    try {
      if (token && minMailingDate) {
        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate
        });
        const responseRiskData = await request('/back_office/api/analytics/risk', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptRiskData = decryptData(responseRiskData);
        setRiskData(decryptRiskData);

        setFilteredMinMailingDate(decryptRiskData.filteredMinMailingDate);

        let filteredTenants = contextTenants;
        if (selectedDepartment || selectedLocation) {
          filteredTenants = filteredTenants?.filter(tenant =>
            decryptRiskData?.tenantIds?.includes(tenant.id)
          );
        }

        let filteredDepartments = contextDepartments;
        if (selectedTenant || selectedLocation) {
          filteredDepartments = filteredDepartments?.filter(department =>
            decryptRiskData?.departments?.includes(department.label)
          );
        }

        let filteredLocations = contextLocations;
        if (selectedTenant || selectedDepartment) {
          filteredLocations = filteredLocations?.filter(location =>
            decryptRiskData?.locations?.includes(location.label)
          );
        }

        contextTenants?.length && setTenants(filteredTenants);
        contextDepartments?.length && setDepartments(filteredDepartments);
        contextLocations?.length && setLocations(filteredLocations);

        return decryptRiskData;
      }
    } catch (error) {
      console.error('[Risk] ERROR:', error);
    } finally {
      setDataReady(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedTenant, selectedDepartment, selectedLocation, minMailingDate, maxMailingDate]);

  const fetchRiskRatingTableData = useCallback(async () => {
    try {
      if (token) {
        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          tenantId: selectedTenant ? selectedTenant.id : null,
          minMailingDate,
          maxMailingDate
        });
        const responseData = await requestWithSecondaryLoading('/back_office/api/analytics/risk_users', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptTableData = decryptData(responseData);

        setTableData(decryptTableData?.riskRatingTableData || []);

        return decryptTableData;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedTenant, selectedDepartment, selectedLocation]);

  useEffect(() => {
    fetchRiskData();
  }, [fetchRiskData]);

  useEffect(() => {
    fetchRiskRatingTableData();
  }, [fetchRiskRatingTableData]);

  const graphContainerRef = useRef(null);
  const trendGraphContainerRef = useRef(null);
  const tableContainerRef = useRef(null);

  const adjustTableHeight = useCallback(() => {
    if (
      graphContainerRef.current && tableContainerRef.current && trendGraphContainerRef.current
    ) {
      const graphHeight = graphContainerRef.current.clientHeight;
      const trendGraphHeight = trendGraphContainerRef.current.clientHeight;
      tableContainerRef.current.style.maxHeight = `${graphHeight}px`;

      if (secondaryLoading || !dataReady) {
        tableContainerRef.current.style.maxHeight = `${trendGraphHeight}px`;
      }
    }
  }, [secondaryLoading, dataReady]);

  useEffect(() => {
    adjustTableHeight();
    window.addEventListener('resize', adjustTableHeight);
  
    return () => {
      window.removeEventListener('resize', adjustTableHeight);
    };
  }, [adjustTableHeight]);

  const exportRiskRatingCsv = async () => {
    try {
      if (token) {
        setDownloadLoading(true);

        const data = encryptData({
          department: selectedDepartment ? selectedDepartment.label : null,
          location: selectedLocation ? selectedLocation.label : null,
          tenantId: selectedTenant ? selectedTenant.id : null
        });
        const response = await fetch('/back_office/api/analytics/risk_users_csv', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data })
        });

        if (!response.ok) {
          setDownloadLoading(false);
          // eslint-disable-next-line no-throw-literal
          throw {error: 'Failed to export CSV file!'};
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        a.download = `risk_rating_${moment().format('MMMM')}.csv`;

        a.textContent = 'Download CSV';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setDownloadLoading(false);
        showToastMessage(null, 'CSV file with Phishing Users downloaded successfully');
      }
    } catch (error) {
      setDownloadLoading(false);
      showToastMessage(error?.error, null, error?.warning);
    }
  };

  return (
    <>
      <UserAnalyticsFilterBar
        loading={loading}
        tenants={tenants}
        departments={departments}
        locations={locations}
        minMailingDate={minMailingDate}
        setMinMailingDate={setMinMailingDate}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
        dateRangeOptionsList={dateRangeOptionsList}
        filteredMinMailingDate={filteredMinMailingDate}
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        selectedDateRange={selectedDateRange}
        setSelectedDateRange={setSelectedDateRange}
        setDateRangeOptionsList={setDateRangeOptionsList}
        maxMailingDate={maxMailingDate}
        setMaxMailingDate={setMaxMailingDate}
        generateReportButtonVisible={false}
      />

      <RiskMetrics
        riskData={riskData}
        loading={loading}
      />

      <div className='trend-graphs-container'>
        <div className="trend-graphs-container-item" ref={graphContainerRef}>
          <div className="trend-graphs-container-item-graph" ref={trendGraphContainerRef}>
            <TrendGraph
              highlited={true}
              title='Human Risk Rate Graph'
              loading={loading}
              minMailingDate={minMailingDate}
              maxMailingDate={maxMailingDate}
              graphData={riskData?.breachRiskTrendGraphData || {}}
              currValue={riskData?.phishRisk || '0'}
              diffValue={riskData?.diffPhishRisk || 0}
              tooltipName={'Human Risk Rate'}
              percentage={true}
              plusIsPositive={false}
              stepSizeY={10}
              percentageTicks={true}
            />
          </div>
          <div className="trend-graphs-container-item-chart">
            <h4>Risk Users</h4>
            <ChartPieRiskLevels
              loading={loading}
              dataReady={dataReady}
              chartData={riskData?.riskLevelsChartData || []}
              riskData={riskData}
            />
          </div>
        </div>

        <div className="trend-graphs-container-item-table" ref={tableContainerRef}>
          <ProneTable
            tablesData={tableData}
            loading={!dataReady}
            callback={exportRiskRatingCsv}
            downloadLoading={downloadLoading}
            height={graphContainerRef?.current?.clientHeight ? graphContainerRef?.current?.clientHeight - 120 : 0}
          />
        </div>
      </div>
    </>
  );
}
