import React, { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import Modal from 'react-modal';
import { v4 as uuid } from 'uuid';
import moment from "moment";
import cn from 'classnames';

import { ActionTypeOption, EmailTypeOption } from "../../ReactSelect/ReactSelectOption";
import { CustomizedTooltipDisabled, CustomizedTooltipFalsePositive } from "../../Tooltips/Tooltip";
import { AuthContext } from "../../../context/AuthContext";
import { Pagination } from "../../Pagination/Pagination";
import ReactSelect from "../../ReactSelect/ReactSelect";
import { useCrypto } from "../../../hooks/crypto.hook";
import { dateRangeOptions, itemsOnPage } from "../../../config/config";
import { useHttp } from "../../../hooks/http.hook";
import { Loader } from "../../Loader/Loader";
import { AnalyticsGeneralContext } from "../../../context/AnalyticsGeneralContext";
import {ReactComponent as Alert} from '../../../images/icons/Alert triangle 2.svg'
import { customStyles } from '../../../styles/customStyles';
import './AllUsersActivity.css';

export const AllUsersActivity = memo(({
  tenantId, userId, startDate, endDate, selectedDateRange, startDateParam,
  endDateParam, emailTypeParam, actionTypeParam, removeQueryParams,
  dateRangeParam, setSelectedDateRange, params, department, location,
  departmentParam, locationParam
}) => {
  const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
  const { request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token, user, showToastMessage } = useContext(AuthContext);
  const { generalLoading, setGeneralLoading } = useContext(AnalyticsGeneralContext);
  const { encryptData, decryptData } = useCrypto();
  const emailRefs = useRef([]);
  const subjectRefs = useRef([]);
  const listRef = useRef(null);

  const [falsePositiveChangingLoading, setFalsePositiveChangingLoading] = useState(false);
  const [additionalLoading, setAdditionalLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const [selectedActionId, setSelectedActionId] = useState(0);
  const [mailingHistory, setMailingHistory] = useState([]);
  const [userActions, setUserActions] = useState({});
  const [actionTypes, setActionTypes] = useState([]);
  const [emailTypes, setEmailTypes] = useState([]);
  const [emailTypesInitial, setEmailTypesInitial] = useState([]);
  const [prevEmailTypes, setPrevEmailTypes] = useState([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [emailType, setEmailType] = useState(null);
  const [actionType, setActionType] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [emailTooltipData, setEmailTooltipData] = useState([]);
  const [subjectTooltipData, setSubjectTooltipData] = useState([]);
  const [resendEmailModalOpen, setResendEmailModalOpen] = useState(false);
  const [resendEmailData, setResendEmailData] = useState({});

  useEffect(() => {
    const newEmailTooltipData = mailingHistory?.map((_, index) => {
      const emailTd = emailRefs.current[index];
      if (emailTd) {
        return emailTd.scrollWidth > emailTd.clientWidth;
      }
      return false;
    });
    setEmailTooltipData(newEmailTooltipData);

    const newSubjectTooltipData = mailingHistory?.map((_, index) => {
      const subjectId = subjectRefs.current[index];
      if (subjectId) {
        return subjectId.scrollWidth > subjectId.clientWidth;
      }
      return false;
    });
    setSubjectTooltipData(newSubjectTooltipData);
  }, [mailingHistory]);

  useEffect(() => {
    setActionType(null);
    setEmailType(null);
    setPage(1);
    setEmailTypesInitial([]);
  }, [tenantId, userId, startDate, endDate, department, location]);

  useEffect(() => {
    setPage(1);

    if (emailTypeParam) {
      let label = emailTypeParam?.at(0)?.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').trim().replace(/\s+/g, ' ').replace(/^./, str => str.toUpperCase());
      if (emailTypeParam.includes('mandatory_training')) label = 'All Mandatory Training';
      setEmailType({
        value: emailTypeParam,
        label
      });
      setActionType(null);
    }

    if (actionTypeParam) {
      setActionType({
        value: actionTypeParam,
        label: (" " + actionTypeParam?.at(0))?.replace(/([A-Z])/g, ' $1').trim()?.replace(/^./, str => str.toUpperCase())
      });

      setEmailType(null);
    }

    dateRangeParam && setSelectedDateRange(dateRangeParam !== '-1'
      ? dateRangeOptions.find(option => String(option.value) === dateRangeParam)
      : { value: -1, label: 'Custom range' }
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTypeParam, actionTypeParam, dateRangeParam]);

  useEffect(() => {
    setGeneralLoading(secondaryLoading || additionalLoading)
  }, [secondaryLoading, setGeneralLoading, additionalLoading]);

  const fetchMailingHistory = useCallback(async () => {
    setAdditionalLoading(true);
    try {
      if (token) {
        setSelectedRow(-1);
        setDetailsVisible(false);

        const data = encryptData({
          tenantId,
          userId,
          startDate: startDateParam || startDate,
          endDate: endDateParam || endDate,
          emailType: emailTypeParam?.length ? emailTypeParam : emailType?.value,
          actionType: actionTypeParam?.length ? actionTypeParam : actionType?.value,
          page,
          department: departmentParam || department,
          location: locationParam || location
        });
        const fetched = await requestWithSecondaryLoading('/back_office/api/analytics/user_analytic/all_users_activity', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        const decryptHistory = decryptData(fetched);

        setMailingHistory(decryptHistory?.rows);
        setUserActions(decryptHistory?.userActions);
        setCount(decryptHistory?.count);
        setActionTypes(decryptHistory?.actionTypes);
        setEmailTypes(decryptHistory?.emailTypes);

        !emailTypesInitial.length && setEmailTypesInitial(decryptHistory?.emailTypes);
        !emailType && setPrevEmailTypes(decryptHistory?.emailTypes);

        return decryptHistory;
      }
    } catch (error) {}
    finally {
      setTimeout(() => {
        removeQueryParams();
        setAdditionalLoading(false);
      }, 300)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailType, page, actionType, startDate, endDate, tenantId, userId, department, location]);

  useEffect(() => {
    fetchMailingHistory();
  }, [fetchMailingHistory]);

  let preparedEmailTypesForOptionList = emailTypesInitial;
  if (!emailType || actionType || tenantId || userId || selectedDateRange?.value !== 0) preparedEmailTypesForOptionList = emailTypes;
  if (emailType && !actionType) preparedEmailTypesForOptionList = prevEmailTypes;

  const emailTypesOptionList = preparedEmailTypesForOptionList?.map(type => (
    {
      value: type,
      label: type?.replace(/_/g, ' ')?.replace(/([A-Z])/g, ' $1').trim()?.replace(/\s+/g, ' ')?.replace(/^./, str => str.toUpperCase())
    }
  ));

  const actionTypesOptionList = actionTypes?.map(type => (
    {
      value: type,
      label: (" " + type)?.replace(/([A-Z])/g, ' $1').trim()?.replace(/^./, str => str.toUpperCase()),
    }
  ))

  const updateFalsePositiveActionSatus = async (selectedActionId, falsePositive) => {
    try {
      const data = encryptData({ actionId: selectedActionId, falsePositive });
      const updateStatus = await request(
        `/back_office/api/user/update_action_false_positive`,
        'POST',
        { data },
        { Authorization: `Bearer ${token}` }
      );

      return updateStatus;
    } catch (error) { }
  }

  const handleFalsePositiveCheckboxChange = async (selectedActionId, falsePositive, selectedAction) => {
    setFalsePositiveChangingLoading(true);
    try {
      const updateStatus = await updateFalsePositiveActionSatus(selectedActionId, falsePositive);
      showToastMessage(updateStatus.error, updateStatus.message);

      if (updateStatus.message) {
        setUserActions(prevActions => ({
          ...prevActions,
          [selectedAction]: prevActions[selectedAction].map(action => {
            if (action.id === selectedActionId) {
              return {
                ...action,
                falsePositive: !action.falsePositive
              };
            }
            return action;
          })
        }));
      }
    } catch (error) {
      showToastMessage(`Unable to mark action as ${falsePositive ? 'False Positive' : 'Not False Positive'}!`);
    } finally {
      setFalsePositiveChangingLoading(false);
      setSelectedActionId(0);
    }
  };

  const handleEmailChange = (data) => {
    setEmailType(data);
    setPage(1);
  };

  const handlePageChange = (page) => {
    setTimeout(() => {
      setPage(page);
    }, 100)
  };

  const handleActionChange = (data) => {
    setActionType(data);
    setEmailType(null);
    setPage(1);
  };

  const exportMailingCsv = async (
    tenantId, userId, emailType, actionType, startDate, endDate, department, location
  ) => {
    try {
      if (token) {
        setDownloadLoading(true);

        const data = encryptData({
          tenantId,
          userId,
          emailType,
          actionType,
          startDate,
          endDate,
          department,
          location
        });
        const response = await fetch('/back_office/api/analytics/user_analytic/activity_csv_all_users', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ data })
        });

        if (response.status === 413) {
          setDownloadLoading(false);
          // eslint-disable-next-line no-throw-literal
          throw {warning: 'File is too large to download! Please, select a smaller date range.'};
        }

        if (!response.ok) {
          setDownloadLoading(false);
          // eslint-disable-next-line no-throw-literal
          throw {error: 'Failed to export CSV file!'};
        }

        const blob = await response.blob();
        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;

        if (userId) a.download = `mailing_and_actions_list__UserID-${userId}__` + new Date().toISOString().split('T')[0] + '.csv';
        else a.download = 'mailing_and_actions_list_'+ new Date().toISOString().split('T')[0] + '.csv';

        a.textContent = 'Download CSV';

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        setDownloadLoading(false);
        showToastMessage(null, 'CSV file with Mailing and Actions list downloaded successfully');
      }
    } catch (error) {
      setDownloadLoading(false);
      showToastMessage(error?.error, null, error?.warning);
    }
  };

  useEffect(() => {
    if (params.size && !generalLoading) {
      const elementTop = listRef.current.getBoundingClientRect().top + window.scrollY - 52;
      window.scrollTo({
        top: elementTop,
        behavior: 'smooth'
      });
    }
  }, [generalLoading, params]);

  const closeResendEmailModal = () => {
    setResendEmailModalOpen(false);
    setResendEmailData({});
    setSelectedRow(-1);
  }

  const resendEmail = async () => {
    try {
      if (token) {
        const data = encryptData(resendEmailData);

        const response = await requestWithSecondaryLoading(`/back_office/api/analytics/resend_email`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        showToastMessage(response.error, response.message);
      }
    } catch (error) {
      console.log(error);
      showToastMessage(error);
    } finally {
      closeResendEmailModal();
    }
  };

  return (
    <div ref={listRef}>
      <div className='history-card-analytics-mailing'>
        <>
          <div className='analytics-table-container'>
            <div className='analytics-select-container'>
              <div className='analytics-select-container-mailing'>
                <div
                  className={cn(`action-type-select action-type-select-${actionType?.value}`,
                    {'action-type-select--disabled': !actionTypesOptionList.length || secondaryLoading || generalLoading}
                  )}>
                  <label className={cn('userslist-select-label input-container-history-actions-item', {
                    "input-edit-history-loading": secondaryLoading || generalLoading
                  })}>
                    <ReactSelect
                      classNames={'multiselect-action-type'}
                      options={actionTypesOptionList}
                      optionComponent={ActionTypeOption}
                      placeholder={'Action Type...'}
                      value={actionType}
                      onChange={handleActionChange}
                      isSearchable={true}
                      isClearable={true}
                      optionHeight={32}
                      rows={10}
                      isDisabled={!actionTypesOptionList.length || generalLoading}
                    />
                  </label>
                </div>
                <div className={cn(`email-type-select email-type-select-${emailType?.value}`,
                  {'email-type-select--disabled': !emailTypesOptionList.length || secondaryLoading || generalLoading}
                )}>
                  <label className={cn('userslist-select-label input-container-history-actions-item', {
                    "input-edit-history-loading": secondaryLoading || generalLoading
                  })}>
                    <ReactSelect
                      classNames={'multiselect-action-type'}
                      options={emailTypesOptionList}
                      optionComponent={ EmailTypeOption }
                      placeholder={'Email Type...'}
                      value={emailType}
                      onChange={handleEmailChange}
                      isSearchable={true}
                      isClearable={true}
                      optionHeight={32}
                      rows={10}
                      isDisabled={!emailTypesOptionList.length || generalLoading}
                    />
                  </label>
                </div>
              </div>

              <div>
                {user?.paymentPlanId !== 3 && user?.paymentPlanId !== 4 &&
                  <CustomizedTooltipDisabled
                    position={'left'}
                    text1={'This feature is available'}
                    text2={'in the Professional'}
                    text3={'and Enterprise plan'}
                  />
                }
                {mailingHistory.length
                  ? !downloadLoading
                    ? <button
                        className='actions-download'
                        type="button"
                        onClick={() => exportMailingCsv(
                          tenantId, userId, emailType?.value, actionType?.value, startDate, endDate, department, location
                        )}
                        disabled={user.paymentPlanId !== 3 && user.paymentPlanId !== 4}
                      >
                        Export csv
                      </button>
                    : <button
                        className='csv-download-loading'
                        type="button"
                      >
                        <span className="mini-loader-csv"></span>
                      </button>
                  : <div style={{'width': '120px'}}></div>
                }
              </div>
            </div>
          </div>

          {!!mailingHistory.length
            ? !additionalLoading && !secondaryLoading
              ? <table className="analytics-all-users-table">
                  <thead className="table-th-analytics">
                    <tr className="tr-grid-analytics-all-users tr-analytics">
                      <th>
                        User Name
                      </th>
                      <th>
                        User Email
                      </th>
                      <th>
                        Segment Name
                      </th>
                      <th>
                        Template Subject
                      </th>
                      <th>
                        Email Type
                      </th>
                      <th>
                        Sending time
                      </th>
                      <th>
                        Actions
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody className="table-body table-body-analytics">
                    {mailingHistory?.map((item, index) => {
                        const isVisible = selectedRow === item.id && detailsVisible;
                        return (
                          <tr
                            key={uuid()}
                            className={cn("tr-grid-analytics-all-users tr-analytics", {
                              "tr-grid-analytics-all-users--open": isVisible
                            })}
                            onClick={() => setSelectedRow(item.id)}
                            >
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>{item?.firstName} {item?.secondName}</td>
                              <td
                                ref={el => (emailRefs.current[index] = el)}
                                className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}
                                title={emailTooltipData[index] ? item?.email : ''}
                              >
                                {item?.email}
                              </td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>{item?.segmentName}</td>
                              <td
                                ref={el => (subjectRefs.current[index] = el)}
                                className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}
                                title={subjectTooltipData[index] ? (item?.subject || item?.templateSubject) : ''}
                              >
                                {item?.subject || item?.templateSubject}
                              </td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>
                                {item?.name?.replace(/_/g, ' ')?.replace(/([A-Z])/g, ' $1').trim()?.replace(/\s+/g, ' ')?.replace(/^./, str => str.toUpperCase())}
                              </td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>
                                {moment(item?.sendouttimestamp).utc().format('MM/DD/YYYY HH:mm:ss')}
                              </td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>
                                {userActions?.[`${item?.userId}_${item?.segmentId}_${item?.emailTemplateId}`]
                                  ?  <button
                                      type='button'
                                      className={cn('table-option-analytics', { 'table-option-analytics--active': isVisible })}
                                      onClick={() => {
                                        isVisible
                                          ? setDetailsVisible(!detailsVisible)
                                          : setDetailsVisible(true)
                                        setSelectedRow(item.id)
                                      }}
                                    >
                                      <span className={cn("analytics-list", {
                                        "analytics-active-list": isVisible
                                      })}>
                                        {isVisible ? 'Hide Actions' : 'View Actions'}
                                      </span>
                                    </button>
                                  : <span>&#8722;</span>
                                }
                              </td>
                              <td className={cn('list-td-analytics', {'list-td-analytics--open': isVisible})}>
                                {item?.simulated &&
                                  <CustomizedTooltipDisabled
                                    position={'left'}
                                    text1={'This record is a simulation, and the email was not sent.'}
                                    text2={'This means that this email was sent to the user within 1 year.'}
                                    text3={'If you still want to resend it, click this icon'}
                                    button={
                                      <button
                                        className='alert-resend-button'
                                        type='button'
                                        onClick={() => {
                                          setResendEmailModalOpen(true);
                                          setResendEmailData(mailingHistory.find(history => history.id === item.id) || {});
                                        }}
                                      >
                                        <Alert />
                                      </button>
                                    }
                                  />
                                }
                              </td>
                              {isVisible && detailsVisible &&
                              <>
                                <td
                                  className={cn('analytics-title-td', {'analytics-title-td--open': selectedRow === item.id && detailsVisible})}
                                  onClick={() => {
                                    setDetailsVisible(!detailsVisible)
                                    setSelectedRow(-1)
                                  }}
                                >
                                  <span className='analytics-title'>User actions</span>
                                </td>
                                <td className={cn("analytics-all-users-table-additional", {
                                  "analytics-all-users-table-additional--open": selectedRow === item.id && detailsVisible
                                })}>
                                  <ul>
                                    <li className='analytics-li analytics-ul-title' key={user?.email}>
                                      <span>Action Type</span>
                                      <span>Created At</span>
                                      <span>False Positive</span>
                                    </li>
                                      {userActions?.[`${item?.userId}_${item?.segmentId}_${item?.emailTemplateId}`]?.map(action => {
                                        const falsePositiveButtonDisabled =
                                          (user.role !== 'owner' && user.role === 'tenant-admin' && !user?.tenants?.falsePositiveActionAccess) ||
                                          (user.role === 'tenant-admin' && !user?.tenants?.falsePositiveActionAccess) ||
                                          user.role === 'admin' ||
                                          falsePositiveChangingLoading ||
                                          moment(action?.createdAt).format('YYYY-MM-DD') < firstDayOfMonth

                                        let text1 = 'You do not have access to make these changes';
                                        let text2 = '';

                                        if (
                                          (user.role === 'owner' || (user.role === 'tenant-admin' && user?.tenants?.falsePositiveActionAccess))
                                            && moment(action?.createdAt).format('YYYY-MM-DD') >= firstDayOfMonth
                                          ) {
                                          text1 = 'Mark as';
                                          text2 = action?.falsePositive ? 'Not False Positive' : 'False Positive';
                                        } else if (
                                          (user.role === 'owner' || (user.role === 'tenant-admin' && user?.tenants?.falsePositiveActionAccess))
                                            && moment(action?.createdAt).format('YYYY-MM-DD') < firstDayOfMonth
                                        ) {
                                          text1 = 'You can only change the status of actions that were created in the';
                                          text2 = 'CURRENT MONTH'
                                        }
                                          return (
                                            <li className={cn('analytics-li', {'false-positive-anylytics': action?.falsePositive})} key={action?.id}>
                                              <span>{(" " + action?.type)?.replace(/([A-Z])/g, ' $1').trim()?.replace(/^./, str => str.toUpperCase())}</span>
                                              <span>{moment(action?.createdAt).utc().format('MM/DD/YYYY HH:mm:ss')}</span>
                                              <span>
                                                <label
                                                  className={"slider-checkbox"}
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className='checkbox-input'
                                                    checked={action?.falsePositive}
                                                    onClick={() => setSelectedActionId(action.id)}
                                                    onChange={() => handleFalsePositiveCheckboxChange(
                                                      action.id, !action?.falsePositive, `${item?.userId}_${item?.segmentId}_${item?.emailTemplateId}`
                                                    )}
                                                    disabled={falsePositiveButtonDisabled}
                                                  />
                                                  <CustomizedTooltipFalsePositive
                                                    showFragment={!falsePositiveChangingLoading}
                                                    text1={text1}
                                                    text2={text2}
                                                    component={
                                                      <span className={cn("slider", {
                                                        "slider-checkbox-disabled": falsePositiveButtonDisabled
                                                      })} />
                                                    }
                                                  />
                                                  {action.id === selectedActionId && falsePositiveChangingLoading &&
                                                    <span className="mini-loader"></span>
                                                  }
                                                </label>
                                              </span>
                                            </li>
                                          )
                                      })}
                                  </ul>
                                </td>
                              </>
                            }
                          </tr>
                        )})
                    }
                  </tbody>
                </table>
              : <Loader />
            : !secondaryLoading
                ? <div>
                    <p className="history-description">
                      There are no mailing history yet.
                    </p>
                  </div>
                : <Loader />
          }
        </>
      </div>
      {!!mailingHistory.length && !additionalLoading &&
        <Pagination
          siblingCount={2}
          currentPage={page}
          totalCount={count}
          pageSize={itemsOnPage}
          onPageChange={handlePageChange}
        />
      }
      <Modal
        isOpen={resendEmailModalOpen}
        onRequestClose={closeResendEmailModal}
        style={customStyles}
        closeTimeoutMS={400}
      >
        <div className='modal-container-resend-email'>
          <button onClick={closeResendEmailModal} className="modal-close"></button>
          <h2 className="modal-title">Resend email</h2>
          <span>
            If we need to send a specific email within one training program, but we see that<br/>
            the user has already been sent an email with similar content within the year,<br/>
            but in another training program, we don't send it again, we just transfer all<br/>
            previous progress for the same email.<br/><br/>
            But you can send email <strong>{resendEmailData.templateSubject || resendEmailData.subject}</strong><br/>
            for <strong>{resendEmailData.email}</strong> again if you need to.
          </span>

          <div className='modal-container-buttons'>
            <button
              type='button'
              className='button'
              onClick={resendEmail}
            >
              Yes, send
            </button>
            <button
              type='button'
              className='button'
              onClick={closeResendEmailModal}
            >
              No, don't send
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
});
