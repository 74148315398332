import React, { useCallback, useEffect, useContext, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import JsonView from 'react18-json-view';
import Modal from 'react-modal';
import cn from 'classnames';
import moment from 'moment';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { AuthContext } from '../../context/AuthContext';
import { useTitle } from '../../hooks/title.hook';
import { getSubjectFromDescription } from '../../common/getDescription';
import { Pagination } from '../../components/Pagination/Pagination';
import { itemsOnPage } from '../../config/config';
import { CustomizedTooltipDisabled } from '../../components/Tooltips/Tooltip.js';
import alert from '../../images/icons/Alert triangle 2.svg'
import 'react18-json-view/src/style.css';
import './ReportedPhish.css';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
  content: {
    padding: '0',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: '0px 2px 10px rgba(52, 50, 50, 0.2)',
    borderRadius: '4px'
  },
};

export const NewReports = () => {
  useTitle("PhishFirewall | Reported phish - New")

  const { token } = useAuth();
  const { loading, request } = useHttp();
  const { showToastMessage, fetchPhishReports, phishReports } = useContext(AuthContext);
  const { ref, inView } = useInView({ threshold: 0 });
  const { encryptData, decryptData } = useCrypto();
  const { state } = useLocation();

  const [newPhishReports, setNewPhishReports] = useState([]);
  const [report, setReport] = useState({});
  const [selectedRow, setSelectedRow] = useState(-1);
  const [reportVisible, setReportVisible] = useState({
    visible: false,
    selectedRow
  });
  const [reportsLoading, setReportsLoading] = useState(false);
  const [oneReportLoading, setOneReportLoading] = useState(false);
  const [reportStatus, setReportStatus] = useState('');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [fileContent, setFileContent] = useState('');
  const [previewType, setPreviewType] = useState('');
  const [selectedReports, setSelectedReports] = useState([]);
  const [selectedReportsStatus, setselectedReportsStatus] = useState('');
  const [page, setPage] = useState(1);

  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
    setPreviewType('');
  }

  const fetchNewPhishReports = useCallback(async () => {
    try {
      setReportsLoading(true);
      if (token && !state) {
        const fetched = await request(`/back_office/api/phish_reports/by_status/${page}/new`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptReports = decryptData(fetched);
        setNewPhishReports(decryptReports.userstoriesByStatuses);
        showToastMessage(fetched.error);
        setSelectedRow(-1);
        setReportVisible({
          visible: false,
          selectedRow: -1
        });
        setReport({});

        return decryptReports;
      } else if (token && state) {
        const stateValue = state?.value;
        const fetched = await request(`/back_office/api/phish_reports/userstory/${stateValue}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptReport = decryptData(fetched);
        setNewPhishReports([decryptReport]);
        setReport(decryptReport);
        setSelectedRow(stateValue);
        setReportVisible({
          visible: true,
          selectedRow: stateValue
        });

        showToastMessage(fetched.error, fetched.message, fetched.warning);
        setFileContent(JSON.parse(decryptReport.raw_file_data));

        return decryptReport;
      }
    } finally {
      setReportsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, page, state]);

  useEffect(() => {
    setNewPhishReports([]);
    fetchNewPhishReports();
  }, [fetchNewPhishReports]);

  const updateReportStatus = (reportId) => {
    const status = newPhishReports.find(r => r.id === reportId)?.status_slug;
    setReportStatus(status);
  }

  const getPhishReport = async (selectedRow) => {
    try {
      if (token) {
        setOneReportLoading(true);
        const fetched = await request(`/back_office/api/phish_reports/userstory/${selectedRow}`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptReport = decryptData(fetched);
        setReport(decryptReport);
        showToastMessage(fetched.error, fetched.message, fetched.warning);
        setFileContent(JSON.parse(decryptReport.raw_file_data));
        setOneReportLoading(false);
        return decryptReport;
      }
    } catch (error) { setOneReportLoading(false); }
  };

  const updateUserstoryStatus = async (selectedRow, statusValue, version, userEmail, subject, attachments) => {
    try {
      if (token) {
        const data = { statusValue, version, userEmail, subject, attachments };
        const fetched = await request(`/back_office/api/phish_reports/userstory/${selectedRow}`, 'PATCH', data, {
          Authorization: `Bearer ${token}`
        })

        showToastMessage(fetched.error, fetched.message, fetched.warning);

        if (fetched.message) {
          setSelectedRow(-1);
          setReportVisible(prev => ({
            ...prev,
            visible: false,
            selectedRow: -1
          }));
          setSelectedReports([]);
          setselectedReportsStatus('');
        }

        fetchPhishReports();
        fetchNewPhishReports();

        return fetched;
      }
    } catch (error) {
      fetchPhishReports();
      fetchNewPhishReports();
      showToastMessage('Could not update Phish Report status');
    }
  };

  const updateUserstoriesStatuses = async (selectedRows, statusValue) => {
    try {
      setReportsLoading(true);
      if (token) {
        const preparedReports = newPhishReports
          .filter(r => selectedRows.includes(r.id))
          .map(r => ({
            statusValue,
            storyId: r.id,
            version: r.version,
            userEmail: r.user_email,
            subject: r.email_subject,
            attachments: [r.snapshot_attached_url]
          }));

        const data = encryptData(preparedReports);
        const res = await request(`/back_office/api/phish_reports/userstories`, 'PATCH', { data }, {
          Authorization: `Bearer ${token}`
        })
        showToastMessage(res.error, res.message, res.warning);

        if (res.message) {
          setSelectedRow(-1);
          setReportVisible(prev => ({
            ...prev,
            visible: false,
            selectedRow: -1
          }));
          setSelectedReports([]);
          setselectedReportsStatus('');
        }

        setReportsLoading(false);
        fetchPhishReports();
        fetchNewPhishReports();

        return res;
      }
    } catch (error) {
      setReportsLoading(false);
      fetchPhishReports();
      fetchNewPhishReports();
      showToastMessage('Unable to update the status of some Phish Reports');
    }
  };

  useEffect(() => {
    if (!inView) {
      setReportVisible({
        visible: false,
        selectedRow: -1
      })
    }
  }, [inView]);

  const closePane = () => {
    setReportVisible({
      visible: false,
      selectedRow: -1
    })
  };

  const handlePageChange = (page) => {
    setTimeout(() => {
      setPage(page);
    }, 100)
  };

  return (
    <>
      <div className='reports-card'>
        <div className='reports-card-title-action'>
          <h3 className='reports-card-title'>New reports</h3>
          <div className={cn('reports-additional-actions--3', {
            'reports-additional-actions--hide': !selectedReports.length,
            'reports-additional-actions--visible': selectedReports.length,
          })}>
            <span>Identify as:</span>
            <div className='button-loader'>
              {loading && selectedReportsStatus === 'phish' && <span className="loader-small"></span>}
              <button
                className={cn('reports-additional-actions-button', {
                  'reports-additional-actions-button--active': reportStatus === 'phish'
                })}
                onClick={() => {
                  setselectedReportsStatus('phish')
                  updateUserstoriesStatuses(selectedReports, 'phish')
                }}
                disabled={loading}
              >
                Phish
              </button>
            </div>
            <div className='button-loader'>
              {loading && selectedReportsStatus === 'not-phish' && <span className="loader-small"></span>}
              <button
                className={cn('reports-additional-actions-button', {
                  'reports-additional-actions-button--active': selectedReportsStatus === 'not-phish'
                })}
                onClick={() => {
                  setselectedReportsStatus('not-phish')
                  updateUserstoriesStatuses(selectedReports, 'not-phish')
                }}
                disabled={loading}
              >
                Not phish
              </button>
            </div>
            <div className='button-loader'>
              {loading && selectedReportsStatus === 'spam' && <span className="loader-small"></span>}
              <button
                className={cn('reports-additional-actions-button', {
                  'reports-additional-actions-button--active': selectedReportsStatus === 'spam'
                })}
                onClick={() => {
                  setselectedReportsStatus('spam')
                  updateUserstoriesStatuses(selectedReports, 'spam')
                }}
                disabled={loading}
              >
                Spam
              </button>
            </div>
          </div>
        </div>
        {!reportsLoading
          ? !newPhishReports.length
            ? <span>It's okay, you don't have any new Phish reports yet</span>
            : <div>
              <table className="reports-table reports-table-detailed">
                <thead className="table-th-reports">
                  <tr className="not-clickable-reports tr-grid-reports tr-reports">
                    <th></th>
                    <th>Case number</th>
                    <th>Case Owner</th>
                    <th>Contact Email</th>
                    <th>Email Subject</th>
                    <th>Created At</th>
                    <th>Phish details</th>
                    <th>
                      {selectedReports.length
                        ? <button
                          className='reports-unselect-button'
                          onClick={() => setSelectedReports([])}
                        ></button>
                        : ''}
                    </th>
                  </tr>
                </thead>
                {newPhishReports.length
                  ? <tbody
                    className="table-body table-body-reports"
                  >
                    {newPhishReports.map(r => (
                      <tr
                        key={r.id}
                        className={"tr-grid-reports clickable-row-reports tr-reports"}
                        onClick={() => setSelectedRow(r.id)}
                      >
                        <td
                          className={cn({ 'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id })}
                          onClick={closePane}
                        >

                          {(r?.isClicked || r?.isDownloaded || r?.isCredsShared) &&
                            <CustomizedTooltipDisabled
                              position={'top'}
                              text1={'User reported'}
                              text2={'dangerous interactions'}
                              text3={'with the email'}
                              button={<img src={alert} alt="alert"></img>}
                            />
                          }
                        </td>
                        <td
                          className={cn({ 'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id })}
                          onClick={closePane}
                        >
                          {r.id}
                        </td>
                        <td
                          className={cn({ 'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id })}
                          onClick={closePane}
                        >
                          {r.user_fullname}
                        </td>
                        <td
                          className={cn({ 'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id })}
                          onClick={closePane}
                        >
                          {r.user_email}
                        </td>
                        <td
                          className={cn({ 'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id })}
                          onClick={closePane}
                        >
                          {r.email_subject === 'undefined' ? '' : r.email_subject}
                        </td>
                        <td
                          className={cn({ 'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id })}
                          onClick={closePane}
                        >
                          {moment(r.createdAt).utc().format('MM/DD/YYYY HH:mm:ss')}
                        </td>
                        <td className={cn({ 'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id })}>
                          <button
                            className={cn('reports-button', 'reports-button--close', {
                              'reports-button--open': reportVisible.visible && reportVisible.selectedRow === r.id,
                            })}
                            onClick={() => {
                              setSelectedRow(r.id);
                              setReportVisible(prev => ({
                                ...prev,
                                visible: !reportVisible.visible,
                                selectedRow: r.id
                              }));
                              if (!reportVisible.visible && r.id) {
                                getPhishReport(r.id);
                              }
                              updateReportStatus(r.id)
                            }}
                          >
                            {reportVisible.visible && reportVisible.selectedRow === r.id ? 'Close details' : 'View details'}
                          </button>
                        </td>
                        <td className={cn('reports--open-last', { 'reports--open': reportVisible.visible && reportVisible.selectedRow === r.id })}>
                          <div className="reports-checkbox-action">
                            <label className="checkbox-label">
                              <input
                                type="checkbox"
                                className="checkbox"
                                checked={selectedReports.includes(r.id)}
                                onChange={() => setSelectedReports(prevState => prevState.includes(r.id) ? prevState.filter(id => id !== r.id) : [...prevState, r.id])}
                              />
                              <span className='checkbox-span'></span>
                            </label>
                          </div>
                        </td>
                        {reportVisible.visible && reportVisible.selectedRow === r.id && (
                          <div ref={ref} className="reports-additional">
                            {!oneReportLoading ?
                              <div>
                                {report.isClicked && <span className='reports-alert'>Danger! User clicked on link(s) in the email</span>}
                                {report.isDownloaded && <span className='reports-alert'>Danger! User downloaded something from the email or link</span>}
                                {report.isCredsShared && <span className='reports-alert'>Danger! User shared their credentials</span>}
                                <span className='reports-additional-info'>Additional information</span>
                                <div className='reports-additional-detailed'>
                                  <ul>
                                    <li>
                                      Status
                                      <pre>New</pre>
                                    </li>
                                    <li>
                                      Priority
                                      <pre>Medium</pre>
                                    </li>
                                    <li>
                                      Subject
                                      <pre>{report.subject?.trim()}</pre>
                                    </li>
                                    <li>
                                      Description
                                      <pre>
                                        {report.description
                                          ?.split('Phishing email information:')
                                          ?.at(0)
                                          ?.replace(/^[ \t]+/gm, "")
                                          ?.replace(/\n{3,}/g, "\n\n")
                                          ?.trim()
                                        }
                                      </pre>
                                    </li>
                                    <li>
                                      Phishing email information:
                                      <pre>
                                        {report.description
                                          ?.split('Phishing email information:')
                                          ?.at(1)
                                          ?.split('URLs found in phishing email:')
                                          ?.at(0)
                                          ?.replace(/^[ \t]+/gm, "")
                                          ?.replace(/\n{3,}/g, "\n\n")
                                          ?.trim()
                                        }
                                      </pre>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li>
                                      URLs found in phishing email:
                                      <pre>
                                        {report.description
                                          ?.split('URLs found in phishing email:')
                                          ?.at(1)
                                          ?.replace(/^[ \t]+/gm, "")
                                          ?.replace(/\n{3,}/g, "\n\n")
                                          ?.replaceAll('(dot)', '.')
                                          ?.trim()
                                        }
                                      </pre>
                                    </li>
                                  </ul>
                                </div>
                                <div className='reports-additional-actions'>
                                  <div className='reports-additional-actions--1'>
                                    <button
                                      disabled={!report.snapshot_preview_url}
                                      onClick={() => {
                                        openModal();
                                        setPreviewType('snapshot');
                                      }}
                                    >
                                      Preview snapshot email
                                    </button>
                                    <button
                                      disabled={!fileContent}
                                      onClick={() => {
                                        openModal();
                                        setPreviewType('rawFile');
                                      }}
                                    >
                                      Preview raw file email
                                    </button>
                                  </div>
                                  <div className='reports-additional-actions--2'>
                                    <span>Identify as:</span>
                                    <div className='button-loader'>
                                      {loading && reportStatus === 'phish' && <span className="loader-small"></span>}
                                      <button
                                        className={cn('reports-additional-actions-button', {
                                          'reports-additional-actions-button--active': reportStatus === 'phish'
                                        })}
                                        onClick={() => {
                                          setReportStatus('phish')
                                          updateUserstoryStatus(
                                            r.id, 'phish', report.version, r.user_email,
                                            getSubjectFromDescription(report.description), [report.snapshot_preview_url]
                                          )
                                        }}
                                        disabled={loading || report?.status_extra_info?.name !== 'Ready'}
                                      >
                                        Phish
                                      </button>
                                    </div>
                                    <div className='button-loader'>
                                      {loading && reportStatus === 'not-phish' && <span className="loader-small"></span>}
                                      <button
                                        className={cn('reports-additional-actions-button', {
                                          'reports-additional-actions-button--active': reportStatus === 'not-phish'
                                        })}
                                        onClick={() => {
                                          setReportStatus('not-phish')
                                          updateUserstoryStatus(
                                            r.id, 'not-phish', report.version, r.user_email,
                                            getSubjectFromDescription(report.description), [report.snapshot_preview_url]
                                          )
                                        }}
                                        disabled={loading || report?.status_extra_info?.name !== 'Ready'}
                                      >
                                        Not phish
                                      </button>
                                    </div>
                                    <div className='button-loader'>
                                      {loading && reportStatus === 'spam' && <span className="loader-small"></span>}
                                      <button
                                        className={cn('reports-additional-actions-button', {
                                          'reports-additional-actions-button--active': reportStatus === 'spam'
                                        })}
                                        onClick={() => {
                                          setReportStatus('spam')
                                          updateUserstoryStatus(
                                            r.id, 'spam', report.version, r.user_email,
                                            getSubjectFromDescription(report.description), [report.snapshot_preview_url]
                                          )
                                        }}
                                        disabled={loading || report?.status_extra_info?.name !== 'Ready'}
                                      >
                                        Spam
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className='reports-additional-submit'>
                                  <button
                                    className='reports-additional-submit--cancel'
                                    onClick={() => {
                                      setSelectedRow(-1);
                                      setReportVisible(prev => ({
                                        ...prev,
                                        visible: false,
                                        selectedRow: -1
                                      }));
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                              : <Loader />}
                          </div>
                        )}
                      </tr>
                    ))}
                  </tbody>
                  : <tbody></tbody>
                }
              </table>
            </div>
          : <Loader />}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          closeTimeoutMS={200}
        >
          <div className="reports-modal">
            <button onClick={closeModal} className="reports-modal-close"></button>
            <h4 className="reports-modal-cancel-title">Email preview</h4>
            <div className="reports-modal-cancel-subscription">
              {previewType === 'snapshot' &&
                <img src={report.snapshot_preview_url} alt='Email preview'></img>
              }
              {previewType === 'rawFile' &&
                <JsonView
                  src={fileContent}
                  theme='shapeshifter:inverted'
                  displayDataTypes={false}
                  displayObjectSize={false}
                />
              }
            </div>
            <div className="reports-modal-gotit-button">
              <button
                onClick={closeModal}
              >
                Got It!
              </button>
            </div>
          </div>
        </Modal>
      </div>

      {!state && !loading && newPhishReports.length > 1 &&
        <Pagination
          className="pagination-bar"
          siblingCount={2}
          currentPage={page}
          totalCount={phishReports?.new || 0}
          pageSize={itemsOnPage}
          onPageChange={handlePageChange}
        />
      }
    </>
  )
};
